import React from 'react'

const Privacy = () => {
  return (
    <div className='completecont'>
    <div className="container ">
    <h3 style={{color:'white'}}>Privacy & Policy</h3>
        <div className="upload-container">
                
           <p style={{color:'white'}}>Privacy Policy
Welcome to Text Extractor
This Privacy Policy governs the manner in which [Text
Extractor] collects, uses, maintains, and discloses
information collected from users of the [Text Extractor]
mobile application.<br/><br/>
1. Information Collection and Use: [Text Extractor]
does not collect any personal information from users
for its internal use or storage. We do not require users
to sign up or provide any personal details to use the
application's core features, including the image-to-text
extraction functionality.<br/><br/>
2. Third-Party Services: [Text Extractor] may utilize
third-party services, such as analytics tools, for
the purpose of improving user experience and app
performance. These third-party services may collect
non-personal information about users' interactions
with the application, such as device information,
usage patterns, and geographical location. However,
this information is aggregated and anonymized and is
used solely for analytical purposes.<br/><br/>
3. Changes to This Privacy Policy: [Text Extractor]
reserves the right to update or change this Privacy
Policy at any time. Any changes to the Privacy
Policy will be reflected on this page, and users are
encouraged to review this Privacy Policy periodically
for any updates or modifications.<br/><br/>
4. Modification of Terms: [Text Extractor] reserves the
right to modify or update these terms and conditions.
at any time without prior notice. By continuing to use
[Text Extractor] after any such changes, you agree to
be bound by the revised terms and conditions.
5. Contact Us: If you have any questions or concerns.
about this Privacy Policy or the practices of [Text
Extractor], please contact us at help via Email
Section.
</p>    
        </div>
        
      
        
    </div>
    </div>
  )
}

export default Privacy