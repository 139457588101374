import React from 'react'
import './Navbar.css'
import Imgs from '../../img/core-img/logo.jpg'
const Navbar = () => {
 
  return (
    <>
    <div id="mainNavigation">
      <nav role="navigation">
        <div class=" text-center m-2 border-bottom">
          <img src={Imgs} alt="logo" class="invert"/>
            </div>
       
      </nav>
      <div class="navbar-expand-md">
        <div class="navbar-dark text-center my-2">
          <button class="navbar-toggler w-75" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span> <span class="align-middle">Menu</span>
          </button>
        </div>
        <div class="text-center mt-1 collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav mx-auto ">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">About us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Product</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Policy</a>
            </li>
            {/* <li class="nav-item ">
              <a class="nav-link " href="#">Company</a>
             
            </li> */}
          </ul>
        </div>
      </div>
    </div>
    </>
  )
}

export default Navbar