import React from 'react'
import './Herosec.css'
import { Helmet, HelmetProvider } from 'react-helmet-async';
const Herosec = () => {
  return (
    <>
     <HelmetProvider>
      <div>
        <Helmet>
          <title>Image to text converter - Transform Your Images into Editable Text</title>
          <meta name="description" content="Experience seamless text extraction from images with our web app. It's simple, fast, and incredibly easy to use." />
        </Helmet>
        <div class="hero">
	<h1>Text Extractor <span>Extract Your Text From Here </span></h1>
</div>

      </div>
    </HelmetProvider>

    </>
  )
}

export default Herosec