import React from 'react'

const About = () => {
  return (
    <div className='completecont'>
    <div className="container ">
    <h3 style={{color:'white'}}>About Us</h3>
        <div className="upload-container">
                
           <p style={{color:'white'}}>
    
Welcome to Text Extractor
At Text Extractor, we are passionate about harnessing
the power of technology to simplify your life. Our team
of dedicated developers has crafted an innovative
solution that empowers users to effortlessly
extract text from images using cutting-edge image
recognition technology.<br/><br/>
With our user-friendly interface and powerful
backend algorithms, Text Extractor offers a seamless
experience for converting images into editable text.
Whether you're a student needing to digitize notes,
a professional managing documents on the go, or
anyone seeking to extract text from images quickly
and accurately, our app is here to serve you.<br/><br/>
But Text Extractor is more than just a tool for extracting
text from images. We are committed to continuous
improvement and providing our users with the best
possible experience. That's why we regularly update
our app with new features and enhancements to
ensure that you stay ahead in an ever-evolving digital
landscape.<br/><br/>
Our mission is to make text extraction from images
effortless, efficient, and accessible to everyone. We
believe in the power of technology to transform the
way we work, learn, and communicate, and we're proud
to be at the forefront of this transformation.
Thank you for choosing Text Extractor.</p>    
        </div>
        
      
        
    </div>
    </div>

  )
}

export default About